import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/download',
    name: 'index',
    component: () => import("@/views/index/index"),
    meta: {
      title: '钢琴家-陪伴是最长琴的告白'
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.afterEach((to: any, form: any) => {
  document.title = to.meta?.title || '';
})

export default router
